.blog--related--article {
    margin-top: 20px;

    .blog--related--text {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 2.25rem;
        margin-left: 0.1rem;

        /* Responsive for tablet (max-width: 1024px) */
        @media (max-width: 1024px) {
            font-size: 1.3rem;
            line-height: 2rem;
            margin-left: 0;
        }

        /* Responsive for mobile (max-width: 600px) */
        @media (max-width: 600px) {
            font-size: 1.2rem;
            line-height: 1.75rem;
            margin-left: 0;
        }
    }
}
