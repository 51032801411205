.blogs--list--wrapper {
    padding: 24px;
    margin: 0px 68px;

    // Mobile Styles
    @media (max-width: 768px) {
        margin: 0; // Remove side margins on mobile
        padding: 16px; // Reduce padding for mobile
    }
    
    @media (max-width: 480px) {
        margin-top: 0px !important;
        // Further adjustments for smaller devices
        padding: 12px; // Even less padding
    }

}
.header-component {
    padding: 10px;
    margin-top: 20px;
    margin-top: 74px;

     @media (max-width: 768px) {    
        margin-top: 0px ;
     }
}

