/* ClientReviews.scss */

.client-reviews {
  padding: 2rem 1rem;
  text-align: center;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.review-slider {
  max-width: 100%;
  margin: 0 auto;

  .slick-dots {
    bottom: -30px;

    li {
      button:before {
        font-size: 12px;
        color: #888;
      }

      &.slick-active button:before {
        color: #333;
      }
    }
  }
}

.review-card {
  background: #ffffff;
  height: 400px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0.85rem;
  margin: 0 auto;
  max-width: 90%; /* Ensure the card fits within the mobile view */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }

  &__name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #222;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    color: #777;
    margin-bottom: 1rem;
  }

  &__text {
    p {
      font-size: 14px;
      display: -webkit-box;
    -webkit-line-clamp: 11; /* Limits text to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ellipsis (...) */
    }
    color: #444;
    line-height: 1.6;
  }
}

/* Responsive styles */
@media (max-width: 1024px) {
  .client-reviews__title {
    font-size: 1.8rem;
  }

  .client-reviews__subtitle {
    font-size: 1rem;
  }

  .review-card {
    padding: 0.85rem;

    &__name {
      font-size: 1.3rem;
    }

    &__title {
      font-size: 0.9rem;
    }

    &__text {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 768px) {
  .client-reviews__title {
    font-size: 1.6rem;
  }

  .client-reviews__subtitle {
    font-size: 0.95rem;
  }

  .review-card {
    padding: 0.85rem;

    &__name {
      font-size: 1.2rem;
    }

    &__title {
      font-size: 0.85rem;
    }

    &__text {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 480px) {
  .client-reviews__title {
    font-size: 1.4rem;
  }

  .client-reviews__subtitle {
    font-size: 0.9rem;
  }

  .review-card {
    padding: 0.85rem;
    max-width: 95%; /* Ensure the card uses almost full width on small screens */

    &__name {
      font-size: 1.1rem;
    }

    &__title {
      font-size: 0.8rem;
    }

    &__text {
      font-size: 0.75rem;
    }
  }
}
