.header-container {
  color: #000;
  padding: 90px 95px 0px 95px;
}

.blog--other--content {
  width: 30%;
}

.main--category--name {
  color: #bf40bf;
}

.main--date {
  font-size: 12px;
}

.header-top {
  font-size: 14px;
  color: #aaa;
}

.header-title {
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0;
}

.author-info {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.author-info img {
  border: 1px solid grey;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
  height: 50px;
}

.author-name {
  font-size: 18px;
  font-weight: 600;
}

.author-badge {
  font-size: 14px;
  color: #3be2b0; /* Example color for the badge */
  margin-left: 10px;
}

.author-description {
  font-size: 12px;
  color: #000;
  margin: 2px 0px 0px 0px;
}

.expertise-tags {
  display: flex;
  gap: 10px;
}

.expertise-tag {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

/* Images */
.blog--content img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 8px;
}

/* Blockquotes */
.blog--content blockquote {
  border-left: 4px solid #ccc;
  margin: 20px 0;
  padding-left: 20px;
  color: #666;
  font-style: italic;
}



/* Custom Block - Gallery */
.blog--content .gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin: 20px 0;
}

.blog--content .gallery img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Custom Block - Video */
.blog--content .video {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.blog--content .video iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  border-radius: 8px;
  border: none;
}

@media (max-width: 768px) {

  .blog--info {
    flex-direction: column;
  }

  .header-container {
    padding: 20px;

    .header-title {
      font-size: 18px;
    }
    .author-info {
      align-items: flex-start;
    }
  }

  .blog--other--content {
    width: 100%;
  }

  .blog--content {
    width: 100% !important;
  }

  .blog--content h1 {
    font-size: 2rem;
  }

  .blog--content h2 {
    font-size: 1.75rem;
  }

  .blog--content h3 {
    font-size: 1.5rem;
  }
}

.table-of-contents {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.table-of-contents h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.table-of-contents ul {
  list-style-type: none;
  padding-left: 0;
}

.table-of-contents li {
  margin-bottom: 5px;
}

.table-of-contents .toc-level-3 a {
  font-size: 14px;
}

.table-of-contents .toc-level-4 a {
  font-size: 12px;
  margin-left: 10px;
}

.table-of-contents .toc-level-5 a {
  font-size: 12px;
  margin-left: 20px;
}

.blog--info {
  gap: 30px;
  display: flex;
  .blog--content {
    width: 70%;
  }
}

