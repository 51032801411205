/* ContactFormModal.scss */

/* Modal overlay styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  z-index: 9998;
}

/* Modal content styling */
.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: slideDown 0.4s ease;
  z-index: 9999;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Animation effect */
@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #ff4b5c;
}

/* Form styling */
.form-group-pair {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.form-group {
  width: 100%;
  flex: 1;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  text-align: start;
}

.form-group input,
.form-group textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

textarea {
  resize: vertical;
}

/* Submit button styling */
.submit-button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.submit-button:disabled {
  background-color: #6c757d;
}

/* Notification styling */
.notification {
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 5px;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
}

/* Open modal button styling */
.open-modal-button {
  background-color: #007bff;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.open-modal-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
