.menu--popup {
    height: inherit;
    width: inherit;
    display: none;
    height: 400px;
    z-index: 99999999;
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.menu--services {
    display: flex;
    justify-content: space-between;
    margin: 0px 90px 0px 90px;

    ul {
    list-style: none;
    padding: 0;
}

li {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 5px;
    color: #333; /* Adjust the color to your preference */
}
}

.menu--options {
    padding: 0 20px;
    border-left: 1px solid grey;
    font-size: 12px;
}

.menu--section {
    margin: 10px;
}

/* Add any additional styles here */


.menu--open {
    display: flex;
}

.menu--title {
    font-weight: 500;
}


.menu--list--item {
    &:hover {
        cursor: pointer;    
        color: #bf40bf;
    }
}