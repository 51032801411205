.get--in--touch--wrapper {
  background: #dddd;
  padding: 20px;
  border: 1px solid black;
  border-radius: 2px;
  .button--container {
    width: 100%;
    margin-top: 10px;
  }

  .get--in--touch--details {
    display: flex;
    flex-direction: column;

    .input--wrapper {
        margin-bottom: 10px;
    }
  }
  .get--in--touch--title {
    margin-bottom: 12px;
    font-weight: 500;
  }
}

