.input--wrapper {
    display: flex;
    flex-direction: column;
    .input--error {
      color: red;
      font-size: 12px;
    }

  input {
    height: 100%;
    border-radius: .375rem;
    font-weight: 500;
    padding: 0.55rem;
    border: 1px solid black;
    box-sizing: border-box;
  }

  textarea {
    height: 100%;
    border-radius: .375rem;
    font-weight: 500;
    padding: 0.55rem;
    box-sizing: border-box;
  }

  .input--label {
    font-weight: 500;
    font-size: 12px;
  }
  
  .input--asterisk {
    color: red;
    font-size: 14px;
    font-weight: 500;
  }
  
}
