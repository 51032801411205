.blog-card {
    background: rgba(245, 245, 245, 1);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: translateY(-5px);
    }

    .blog-card__image {
        height: 180px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .blog-card__content {
        padding: 15px;

        .blog-card__title {
            font-size: 1.025rem;
            margin-bottom: 8px;
            color: #333;
            font-weight: 600;
            cursor: pointer;
        }

        .blog-card__description {
            font-size: 0.85rem;
            margin-bottom: 16px;
            color: #666;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .blog-card__footer {
            display: flex;
            justify-content: space-between;
            font-size: 0.75rem;
            color: #999;
        }
    }
}
