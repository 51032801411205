.company--values {
  margin-bottom: 20px;
  .text--Xlarge {
    font-weight: bold;
    margin: 34px;
    text-align: center;
  }

  .section {
    display: flex;
    justify-content: center;
    position: relative;

    .indicator {
      background: #bf40bf;
      padding: 10px;
      border-radius: 50%;
      position: absolute;
      top: 30px;

      @media (max-width: 576px) {
        display: none; /* Remove indicator for mobile */
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      text-align: end;
      padding: 0px 84px 0px 0px;
      border-right: 2px solid #bf40bf;
      flex: 1;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 42px;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        word-spacing: 2px;
      }
    }

    /* New vertical line styling */
    .separator {
      width: 2px;
      background-color: #bf40bf;
      margin: 0 20px; /* Space between the line and the elements */
      height: 100%; /* Full height of the section */

      @media (max-width: 576px) {
        display: none; /* Hide vertical line on mobile */
      }
    }

    img {
      max-width: 100%; /* Make the image responsive */
      width: 50%;
      height: auto; /* Maintain aspect ratio */
      object-fit: cover; /* Ensure the image covers its container */
      padding: 0px 0px 50px 0px;
    }

    .image--container {
      flex: 1;
      padding: 0px 85px 0px 0px;
      display: flex;
      justify-content: center;
    }

    &.reverse {
      flex-direction: row-reverse;
      justify-content: center;

      .image--container {
        padding: 0px 0px 0px 85px;
      }

      .details {
        text-align: left;
        padding: 0px 0px 50px 86px;
        border-left: 2px solid #bf40bf;
        border-right: none;
      }

      @media (max-width: 576px) {
        .details {
          text-align: center; /* Center text on mobile */
          padding: 0px 10px; /* Reduce padding */
          border: none; /* Remove borders on mobile */
          width: 100%; /* Full width for details on mobile */
        }

        .indicator {
          display: none; /* Remove indicator on mobile */
        }

        .separator {
          display: none; /* Ensure vertical line is hidden on mobile */
        }

        .image--container {
          width: 100%; /* Full width for images */
          padding: 0px; /* Remove padding */
          display: flex;
          justify-content: center; /* Center the image */
        }
      }
    }
  }

  /* Tablet view (max-width: 768px) */
  @media (max-width: 768px) {
    .section {
      flex-direction: column; /* Stack items vertically */
      padding: 20px 0; /* Add padding around the sections */

      .details {
        text-align: center; /* Center align text */
        width: 100%; /* Full width on small screens */
        padding: 0px 15px; /* Reduce padding */
        border-right: none; /* Remove border */
        border-left: none; /* Remove reverse border */
      }

      .separator {
        display: none; /* Hide line on smaller screens */
      }

      .image--container {
        width: 100%; /* Full width for images */
        padding: 0px; /* Remove padding */
        img {
          padding: 0;
        }
      }

       &.reverse {
        flex-direction: column;
      }
    }
  }

  /* Mobile view (max-width: 576px) */
  @media (max-width: 576px) {
    .section {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center content on mobile */
      padding: 15px 0;

      &.reverse {
        flex-direction: column;
      }

      .details {
        padding: 0px 10px;
        text-align: center; /* Center text on mobile */
        border: none; /* Remove borders on mobile */
        width: 100%; /* Full width for details on mobile */
      }

      .indicator {
        display: none; /* Remove indicator on mobile */
      }

      .separator {
        display: none; /* Ensure vertical line is hidden on mobile */
      }

      .image--container {
        width: 100%; /* Full width for images */
        padding: 0px; /* Remove padding */
        display: flex;
        justify-content: center; /* Center the image */
         img {
          padding: 0;
        }
      }
    }
  }
}
