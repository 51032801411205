.our-solutions {
  padding: 60px 20px;
  background: radial-gradient(circle at 60% 40%, #ffe8de, transparent 35%), 
            radial-gradient(circle at 20% 75%, #ffedd2, transparent 40%), 
            radial-gradient(circle at 80% 25%, #fffae8, transparent 30%), 
            radial-gradient(circle at 40% 70%, #ffe3d8, transparent 45%), 
            radial-gradient(circle at 60% 55%, #ffdac4, transparent 40%), 
            radial-gradient(circle at 50% 10%, #ffeedc, transparent 25%);

  text-align: center;

 h2 {
  font-size: 2rem; /* Increase font size for a more impactful heading */
  color: #2c3e50; /* Darker color for the heading */
  margin-bottom: 20px;
  font-weight: 700; /* Make the title bold */
  text-transform: uppercase; /* All caps for the heading */
  letter-spacing: 1.5px; /* Add some spacing between letters */
}

 p {
  font-size: 1.2rem;
  color: #000; /* Softer gray color for the text */
  margin-bottom: 40px;
  line-height: 1.6; /* Improve readability with line spacing */
  max-width: 1000px; /* Limit the width for better readability */
  margin-left: auto;
  margin-right: auto; /* Center the paragraph text */
}

  .services-container {
    justify-content: center;
    gap: 30px;
    position: relative;

    .services-wrapper {
      height: 500px;
      max-width: 300px;
      position: relative;
      border-radius: 10px;

      .service-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-width: 300px;
        text-align: center;
        background: linear-gradient(0deg, #003580f2, #00358040);
        position: absolute;
        height: 100%;

        .service-icon {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 10px;

          svg {
            width: 100%;
            height: auto;
            color: #007bff; // Adjust color to match your branding
          }
        }

        h3 {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 15px;
          color: #fff;
          font-weight: 900;
          padding: 10px;
        }

        p {
          font-size: 0.95rem;
          color: #777;
          line-height: 1.6;
          color: #fff;
          padding-left: 10px;
          padding-right: 10px;
          transform: translateY(150%);
          opacity: 0;
        }


        &:hover {

          p {
            transform: translateY(0%);
            opacity: 1;
            transition: all .3s ease;
          }
          backdrop-filter: blur(10px);
          transition: all .3s ease;
          background-color: linear-gradient(0deg, #003580f2, #003580bf);
        }
      }

      img {
        object-fit: cover !important;
        height: 100% !important;
        border-radius: 10px;
      }
    }
  }

  .get-in-touch {
    margin-top: 40px;

    h3 {
      font-size: 1.8rem;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #555;

      a {
        color: #007bff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  /* Media Queries for responsiveness */

  /* Tablet (max-width: 1024px) */
  @media (max-width: 1024px) {
    padding: 40px 15px;

    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 30px;
    }

    .services-container {
      gap: 20px;

      .service-item {
        max-width: 250px;

        .service-icon {
          width: 70px;
          height: 70px;
        }

        h3 {
          font-size: 1.4rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .get-in-touch {
      h3 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.95rem;
      }
    }
  }

  /* Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    padding: 30px 10px;

    h2 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 20px;
    }

    .services-container {
      flex-direction: column;
      gap: 15px;

      .service-item {
        max-width: 100%;

        .service-icon {
          width: 60px;
          height: 60px;
        }

        h3 {
          font-size: 1.3rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .get-in-touch {
      margin-top: 30px;

      h3 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }
}


.slick-arrow {
  &.slick-prev {
    left: -10px !important;
  }
  &.slick-next {
    right: 10px  !important;
  }
  &.slick-prev:before, &.slick-next:before {
    font-size: 50px;
    color: #000;
  }
  svg {
    display: none;
  }
}