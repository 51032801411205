/* textStyles.css */

.text--small {
    font-size: 12px;
    line-height: 16px;
}

.text--medium {
    font-size: 16px;
    line-height: 20px;
}

.text--large {
    font-size: 24px;
    line-height: 28px;
}

.text--Xlarge {
    font-size: 30px;
    line-height: 34px;
}

/* Optional: Additional styles for the p element */
p {
    margin: 0;
    padding: 0;
    color: #333;
}
