
.contact-intro {
  margin: 40px 100px 20px 100px;
  text-align: center;
  margin-top: 105px;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 20px;
  }

  &__subtitle {
    margin: 0px 120px;
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin: 20px 30px;

    &__title {
      font-size: 22px;
    }

    &__subtitle {
      margin: 0px 40px;
      font-size: 14px;
    }
  }

  @media (max-width: 576px) {
    margin: 10px;

    &__title {
      font-size: 20px;
    }

    &__subtitle {
      margin: 0px 20px;
      font-size: 14px;
    }
  }
}

.contact--from {
  margin: 4rem auto;
  padding: 20px 50px;
  border-radius: 10px;
  max-width: 1100px;
  background-color: #f9f9f9;

  .card--wrapper {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }

  .contact--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .contact--header {
      margin-bottom: 20px;
      text-align: center;

      .title {
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }

      .subtitle {
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
        color: #666;
      }
    }

    .contact--details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin: 15px;
      
      @media (max-width: 768px) {
          margin: 0px;
        grid-template-columns: 1fr; /* Stack inputs on smaller screens */
      }

      input,
      textarea {
        width: 100%;
      }

      .textarea--comp {
        grid-column: span 2; /* Message textarea spans full width */
      }
    }

    .button--container {
      text-align: center;
    }
  }
}

/* Tablet view adjustments */
@media (max-width: 1024px) {
  .contact--from {
    padding: 20px 30px;
    margin: 20px auto;

    .card--wrapper {
      width: inherit;
      max-width: inherit;
    }
  }

  .contact--header .title {
    font-size: 22px;
  }

  .contact--header .subtitle {
    font-size: 14px;
  }

  .contact--details {
    gap: 15px;
  }
}

/* Mobile view adjustments */
@media (max-width: 576px) {
  .contact--from {
    padding: 20px;
    margin: 10px;
  }

  .contact--header .title {
    font-size: 20px;
  }

  .contact--header .subtitle {
    font-size: 14px;
  }

  .contact--details {
    grid-template-columns: 1fr; /* Stack inputs */
    gap: 10px;
  }

  .contact--details .textarea--comp {
    width: 100%;
  }

}


.contact--details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 0 auto; /* Center the form */
  padding: 20px;
  max-width: 800px; /* Limit the width for better alignment */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stack inputs on smaller screens */
  }

  input,
  textarea {
    width: 100%;
    padding: 10px; /* Add padding to make inputs look more spacious */
    border: 1px solid #ddd; /* Add a border for input styling */
    border-radius: 5px; /* Round the edges of the inputs */
    font-size: 14px; /* Adjust font size for better readability */
  }

  .textarea--comp {
    grid-column: span 2; /* Ensure the message textarea spans full width */
  }
}

.map-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 768px) {
  .map-flex {
    display: flex;
    flex-direction: column;
  }
}


