.why-choose-section {
  display: flex;
  justify-content: center;
  align-items: center;  

  .why-choose-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;

    .why-choose-left {
      flex: 1;
      img {
        object-fit: contain !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .why-choose-right {
      flex: 1.5;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-size: 24px;
        font-weight: bold;
        color: #212529;
        margin-bottom: 20px;
      }

      .intro-text {
        font-size: 15px;
        color: #6c757d;
        margin-bottom: 20px;
      }

      .features-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 40px;

        .feature-item {
          background: #ffffff;
          padding: 10px;
          border-radius: 10px;
          text-align: center;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

          .feature-icon {
            font-size: 2rem;
            margin-bottom: 10px;
          }

          h3 {
            font-size: 16px;
            color: #212529;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            color: #6c757d;
          }
        }
      }

      .discover-link {
        background-color: #28a745;
        color: #ffffff;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 1.125rem;

        &:hover {
          background-color: #218838;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
    .features-grid {
      grid-template-columns: 1fr !important;
    }
    .why-choose-content {
      flex-direction: column;

      .why-choose-left {
        margin-bottom: 20px;
      }

      .why-choose-right {
        padding-left: 0;
      }
    }
  }
}
