.tab--item {
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  &.tab--panel--selected {
    border-bottom: 3px solid #bf40bf;
    color: #bf40bf;
  }

  &.tab--item--menu {
    &:hover {
      border-bottom: 3px solid #bf40bf;
      color: #bf40bf;
    }
  }
  &.tab--span {
    color: "black";
    padding: 10px;
  }
}
