.tech-section {
  padding: 20px 95px 30px 95px;
  text-align: center;
  background: radial-gradient(circle at 60% 40%, #d8efff, transparent 35%), 
            radial-gradient(circle at 20% 75%, #c9e7ff, transparent 40%), 
            radial-gradient(circle at 80% 25%, #e3d8ff, transparent 30%), 
            radial-gradient(circle at 40% 70%, #d2d8ff, transparent 45%), 
            radial-gradient(circle at 60% 55%, #c8d2ff, transparent 40%), 
            radial-gradient(circle at 50% 10%, #dae4ff, transparent 25%);


  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }

  .tech-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .tech-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    span {
      font-size: 1rem;
      color: #bf40bf;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 12px;
      color: #555;
      margin-top: 5px;
    }
  }

  /* Media Queries for responsiveness */

  /* Tablet (max-width: 1024px) */
  @media (max-width: 1024px) {
    padding: 20px 40px 0px 40px;

    h2 {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    .tech-cards {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
    }

    .tech-card {
      padding: 10px;

      span {
        font-size: 0.9rem;
      }

      p {
        font-size: 11px;
      }
    }
  }

  /* Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    padding: 20px 20px 0px 20px;

    h2 {
      font-size: 1.5rem;
    }

    .subtitle {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }

    .tech-cards {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .tech-card {
      padding: 10px;

      span {
        font-size: 0.8rem;
      }

      p {
        font-size: 10px;
      }
    }
  }
}
