.header-component {
  background-color: #1a1a2e; // Dark background
  color: #ffffff; // Text color
  padding: 20px; // Padding around the header
  text-align: center; // Center-align text

  .header-title {
    font-size: 24px; // Title font size
    font-weight: bold; // Make the title bold
    margin: 0; // Remove default margin
  }

  .header-subtitle {
    font-size: 20px; // Subtitle font size
    margin: 10px 0 0 0; // Add margin above the subtitle
  }
}
