.service--wrapper {
  .div--padded {
    padding: 20px 95px 0px 95px;
  }
  margin-top: 74px;

  .faq-section {
    padding: 20px 95px 20px 95px;
    max-width: 100%;
  }

  .main---services {
    position: relative;
    background-image: url("../../../public/technology.webp");
    background-size: cover;
    background-position: center center;

    .button--container {
      height: auto;
      width: 250px;
      top: 10px;
      z-index: 999;

      button {
        font-size: 12px !important;
        font-weight: 600;
      }

      .button--mas {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        margin-top: 12px;
      }
    }

    /* Add a pseudo-element for the overlay */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .service--details {
      position: relative;
      padding: 40px 96px;
      color: #fff;

      .service--details--title {
        position: inherit;
        font-size: 30px;
        font-weight: 600;
        z-index: 2;
      }

      .service--details--description {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .desc--1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 30px;
          z-index: 2;
        }

        .desc--2 {
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          z-index: 2;
        }
      }
    }
  }

  /* Media Queries */
  
  /* Tablet */
  @media (max-width: 1024px) {

    margin-top: 0px;
    .div--padded, .faq-section {
      padding: 20px 40px 0px 40px;
    }

    .main---services {
      .button--container {
        width: 200px;
      }

      .service--details {
        padding: 30px 40px;

        .service--details--title {
          font-size: 24px;
        }

        .service--details--description .desc--1 {
          font-size: 14px;
        }

        .service--details--description .desc--2 {
          font-size: 12px;
        }
      }
    }
  }

  /* Mobile */
  @media (max-width: 600px) {
    .div--padded, .faq-section {
      padding: 20px;
    }

    .main---services {
      .button--container {
        width: 150px;
      }

      .service--details {
        padding: 20px 20px;

        .service--details--title {
          font-size: 20px;
        }

        .service--details--description .desc--1 {
          font-size: 12px;
        }

        .service--details--description .desc--2 {
          font-size: 10px;
        }
      }
    }
  }
}

.image--with--section--wrapper-bg {
padding-bottom: 15px;
background: radial-gradient(circle at 60% 40%, #ffd6d6, transparent 35%),
            radial-gradient(circle at 20% 75%, #ffb3b3, transparent 40%),
            radial-gradient(circle at 80% 25%, #ffcccc, transparent 30%),
            radial-gradient(circle at 40% 70%, #ff99a1, transparent 45%),
            radial-gradient(circle at 60% 55%, #ff8080, transparent 40%),
            radial-gradient(circle at 50% 10%, #ffb3b3, transparent 25%);

}