.techology-section {
  text-align: center;
  margin-bottom: 40px;
  margin: 74px;
  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  p {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #666;
  }
  .tech-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 50px;


    .tech-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      background-color: #f7faff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%;

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
        color: #333;
      }
    }
  }
}
