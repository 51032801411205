.overflow-hidden {
  overflow: hidden;
}

.ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
  z-index: 1;
}

.video--shadow {
  position: absolute;
  background: #000000b3;
  top: 0;
  height: 100;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.video--container {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  .video--wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .main--video {
      position: relative;

      svg {
        position: absolute;
        background: #ffc30c;
        cursor: pointer;
        height: 15px;
        width: 15px;
        color: #fff;
        border-radius: 50%;
        padding: 10px;
        right: -13px;
        top: -13px;
        z-index: 99999999999999;
      }

      video {
        max-width: 800px;
        aspect-ratio: 16 / 9;
        border-radius: 10px;
      }
    }
  }
}

.hero-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 100px 40px 40px 0;
  border-radius: 10px;
  gap: 40px;

  .content {
    max-width: 50%;

    h1 {
      font-size: 48px;
      line-height: 1.2;
      color: #000;
      z-index: 2;
    }

    p {
      margin-top: 20px;
      font-size: 18px;
      line-height: 1.6;
      color: #000;
      z-index: 2;
    }

    .button--container {
      margin-top: 30px;
      width: 250px;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 2;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        transform: scale(1.05); /* Slight scaling effect on hover */
      }

      animation: pulse 1.5s infinite; /* Pulsing effect */
    }
  }

  .video-gallery {
    cursor: pointer;
    .video {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      height: 370px;
      transition: transform 0.3s ease;

      img {
        object-fit: contain;
        width: auto;
        height: 370px;
        border-radius: 10px;
        transition: transform 0.3s ease; /* Zoom effect on hover */
      }

      &:hover {
        img {
          transform: scale(1.05); /* Zoom in effect */
        }
      }

      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px 15px;
        border-radius: 50%;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding: 0 !important;
  }

  .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100% !important;
    text-wrap: balance;

    h1 {
      font-size: 1.75rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.cms-butons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  .button--container {
    width: 330px;
    button {
        background: linear-gradient(90deg, #ffc30c, #d2202d);
        color: #ffffff;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
      }
  }
  
  @media (max-width: 768px) { 
    gap: 20px;
  }
}

.company-wrapper {
  background: radial-gradient(circle at 51.04% 55.62%, #fff2e2, transparent 37%),
    radial-gradient(circle at 77.85% 32.48%, #fff2e2, transparent 30%),
    radial-gradient(circle at 1.04% 6.9%, #fffcf7, transparent 13%),
    radial-gradient(circle at 33.61% 80.65%, #fff, transparent 27%),
    radial-gradient(circle at 73.06% 76.18%, #ffcdc1, transparent 69%),
    radial-gradient(circle at 66.25% 33.56%, #fbece5, transparent 38%),
    radial-gradient(circle at 18.82% 91.07%, #ffe3dd, transparent 25%),
    radial-gradient(circle at 67.43% 1.35%, #f3c8b3, transparent 14%),
    radial-gradient(circle at 38.26% 35.32%, #fff9f7, transparent 100%),
    radial-gradient(circle at 27.64% 59.27%, #fff9f7, transparent 77%),
    radial-gradient(circle at 55.07% 19.76%, #fee1cf, transparent 100%),
    radial-gradient(circle at 75.76% 58.19%, #ffcdc1, transparent 29%),
    radial-gradient(circle at 50% 50%, #ffeee0, #ffeee0 100%);
}
