.footer {
  color: #000 !important;
  padding: 40px 0;
  background: radial-gradient(circle at 51.04% 55.62%, #fff2e2, transparent 37%), radial-gradient(circle at 77.85% 32.48%, #fff2e2, transparent 30%), radial-gradient(circle at 1.04% 6.9%, #fffcf7, transparent 13%), radial-gradient(circle at 33.61% 80.65%, #fff, transparent 27%), radial-gradient(circle at 73.06% 76.18%, #ffcdc1, transparent 69%), radial-gradient(circle at 66.25% 33.56%, #fbece5, transparent 38%), radial-gradient(circle at 18.82% 91.07%, #ffe3dd, transparent 25%), radial-gradient(circle at 67.43% 1.35%, #f3c8b3, transparent 14%), radial-gradient(circle at 38.26% 35.32%, #fff9f7, transparent 100%), radial-gradient(circle at 27.64% 59.27%, #fff9f7, transparent 77%), radial-gradient(circle at 55.07% 19.76%, #fee1cf, transparent 100%), radial-gradient(circle at 75.76% 58.19%, #ffcdc1, transparent 29%), radial-gradient(circle at 50% 50%, #ffeee0, #ffeee0 100%);
  
  .footer-container {
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    
    /* For mobile and tablet adjustments */
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-section {
    flex: 1 1 16%;
    margin-bottom: 30px;
    
    h3 {
      font-size: 1.5em;
      margin-bottom: 10px;
      color: #FFC30C; /* Optional accent color */
    }
    
    p, ul {
      font-size: 14px;
      line-height: 1.6;
      margin: 0;
      font-weight: 500;
    }
    
    ul {
      list-style: none;
      padding: 0;
      
      li {
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
  }
  
  /* Making sections full-width and centered on smaller devices */
  @media (max-width: 768px) {
    .footer-section {
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    
    h3 {
      font-size: 1.3em;
    }
    
    ul, p {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    .footer-section {
      flex: 1 1 100%;
      text-align: center;
    }
    
    h3 {
      font-size: 1.2em;
    }

    ul, p {
      font-size: 11px;
    }
  }

  .footer-bottom {
    width: 100%;

    text-align: center;
    margin-top: 20px;

    p {
      margin: 10px 0;
      font-size: 12px;
      
      a {
        color: #FFC30C; /* Optional accent color */
        text-decoration: underline;
      }
    }
  }

  .tag--line {
    margin-top: 10px;
  }
}

.ball-footer {
  position: absolute;
  bottom: 0;
  border-radius: 100%;
  opacity: 0.7;
  z-index: 1;
}
