.faq-section {
  margin: 0 auto;
  padding: 20px 8rem 20px 8rem;
  border-radius: 8px;
  background: radial-gradient(circle at 60% 40%, #f0e0d6, transparent 35%), 
            radial-gradient(circle at 20% 75%, #d1c7b7, transparent 40%), 
            radial-gradient(circle at 80% 25%, #e5d9b9, transparent 30%), 
            radial-gradient(circle at 40% 70%, #d3c9a8, transparent 50%), 
            radial-gradient(circle at 60% 55%, #f0e5c8, transparent 40%), 
            radial-gradient(circle at 50% 10%, #d8e3b2, transparent 25%);

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;

    /* Adjust heading size for smaller screens */
    @media (max-width: 768px) {
      font-size: 1.6rem;
    }

    @media (max-width: 480px) {
      font-size: 1.4rem;
    }
  }

  .faq-container {
    .faq-item {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 6px;
      margin-bottom: 10px;
      padding: 15px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
      }

      .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          margin: 0;
          color: #333;
          font-size: 1.2rem;

          /* Adjust font size for smaller screens */
          @media (max-width: 768px) {
            font-size: 1.1rem;
          }

          @media (max-width: 480px) {
            font-size: 1rem;
          }
        }

        .faq-icon {
          font-size: 24px;
          color: #999;

          /* Adjust icon size for smaller screens */
          @media (max-width: 768px) {
            font-size: 20px;
          }

          @media (max-width: 480px) {
            font-size: 18px;
          }
        }
      }

      .faq-answer {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #ddd;
        color: #555;
        font-size: 1rem;

        /* Adjust font size for smaller screens */
        @media (max-width: 768px) {
          font-size: 0.95rem;
        }

        @media (max-width: 480px) {
          font-size: 0.9rem;
        }
      }

      &.active {
        background-color: #e6e6ff;

        .faq-icon {
          color: #333;
        }
      }
    }
  }
}

/* Mobile view adjustments for containers */
@media (max-width: 480px) {
  .faq-section {
    padding: 10px;

    .faq-container {
      .faq-item {
        padding: 10px;

        .faq-answer {
          padding-top: 8px;
        }
      }
    }
  }
}
