.menu--section.menu--enterprise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 10px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  margin-left: 16px;

  .enterprise--content {
    text-align: center;
    max-width: 300px; /* Compact width */
    width: 100%;

    .enterprise--title {
      font-size: 1.6rem; /* Slightly larger font size */
      font-weight: bold;
      margin-bottom: 10px;
      color: #333;
      letter-spacing: 0.5px;
      transition: color 0.3s; /* Smooth color transition */

      &:hover {
        color: #007bff; /* Change color on hover */
      }
    }

    .enterprise--text {
      font-size: 1.1rem; /* Slightly larger text */
      margin-bottom: 20px;
      color: #555;
      line-height: 1.6;
      transition: transform 0.3s; /* Smooth scale effect */

      &:hover {
        transform: scale(1.02); /* Slight zoom effect on hover */
      }
    }

    .button--container {
      align-items: center;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); /* Initial position */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* Final position */
    }
  }
}
