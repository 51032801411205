.about-us-container {
    text-align: center;
    padding: 60px 20px;
    background-color: #f7f8fa;
    position: relative;
    overflow: hidden;
    border-radius: 15px; /* Rounded corners for a modern look */
    
}

.about-us-btn {
    background-color: #ffdd57;
    color: #222;
    padding: 12px 24px; /* Slightly larger padding */
    border-radius: 25px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s, transform 0.3s; /* Smooth hover effect */
}

.about-us-btn:hover {
    background-color: #ffd100; /* Darker yellow on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.about-us-title {
    font-size: 3.5rem; /* Larger title for emphasis */
    font-weight: 700;
    color: #222;
    margin: 0;
    letter-spacing: 1px; /* Adds elegance */
}

.about-us-subtitle {
    font-size: 1.1rem; /* Increased size for readability */
    color: #777;
    margin-bottom: 40px;
    line-height: 1.6; /* Improved spacing for readability */
}

.about-us-stats {
    display: flex;
    justify-content: center;
    gap: 40px; /* Balanced spacing */
    flex-wrap: wrap;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width for small screens */
    max-width: 150px; /* Limit width for larger screens */
    transition: transform 0.3s; /* Smooth scaling effect */
}

.stat-item:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.stat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 36px;
    margin-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    transition: transform 0.3s; /* Smooth scaling effect */
}

.stat-icon.yellow {
    background-color: #ffdd57;
    color: #1e2022;
}

.stat-icon.blue {
    background-color: #89cff0;
    color: #1e2022;
}

.stat-icon:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
}

.stat-info {
    text-align: center;
}

.stat-number {
    font-size: 2rem; /* Consistent size for stats */
    font-weight: 700;
    color: #222;
    margin: 0;
}

.stat-label {
    font-size: 0.875rem; /* Smaller size for labels */
    color: #777;
}

/* Media Queries for additional responsiveness */
@media (max-width: 768px) {
    .about-us-title {
        font-size: 1.8rem; /* Adjust for smaller screens */
    }

    .about-us-subtitle {
        font-size: 0.95rem; /* Adjust for smaller screens */
        margin-top: 10px;
    }

    .about-us-btn {
        padding: 10px 20px; /* Smaller padding for smaller screens */
        font-size: 0.875rem; /* Adjust for smaller screens */
    }

    .stat-item {
        width: 45%; /* Use more width for small screens */
    }
}

@media (max-width: 480px) {
    .about-us-title {
        font-size: 1.2rem; /* Further adjust for very small screens */
    }
    .about-us-subtitle {
        margin-top: 10px;
    }

    .stat-item {
        width: 100%; /* Full width for very small screens */
    }

    .stat-icon {
        width: 80px; /* Smaller icons for very small screens */
        height: 80px; /* Smaller icons for very small screens */
        font-size: 1.5rem; /* Smaller font size */
    }
}
