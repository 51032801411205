.service-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem;
  background-color: #F1F7FD; // Use a light background color for contrast

  .content-container {
    display: flex;
    max-width: 1200px;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    margin: 0px 40px;

    .text-content {
      max-width: 50%;
      h1 {
        font-size: 2rem;
        color: #333;
      }

      p {
        font-size: 0.95rem;
        font-weight: 500;
        color: #555;
        line-height: 1.6;
      }

      .cta-buttons {
        margin-top: 1.5rem;

        .cta-button {
          display: inline-block;
          padding: 0.75rem 1.5rem;
          background-color: #0056b3;
          color: white;
          border-radius: 4px;
          text-decoration: none;
          margin-right: 1rem;

          &.outline {
            background-color: transparent;
            border: 1px solid #0056b3;
            color: #0056b3;
          }
        }
      }
    }

    .image-content {
      max-width: 100%;

      img {
        width: 100%;
        object-fit: contain;
        height: 400px;
      }
    }
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
      text-align: center;

      .text-content, .image-content {
        max-width: 100%;
      }

      .image-content {
        margin-top: 2rem;
      }
    }
  }
}
