.tab--main--container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .all--tabs--container {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping on smaller screens */
        justify-content: center;
    }
    
    .tab--main--wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .tab--item {
        padding: 10px;
        cursor: pointer;
    }
    
    .tab--main--title {
        margin-bottom: 15px;
        font-size: 2rem; /* Make title scalable */
    }
    
    .tab--main--container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    /* Tech grid styling */
    .tech-grid {
        display: flex;
        justify-content: space-around;
        padding: 20px;
        flex-wrap: wrap;
        gap: 20px; /* Add gap between items */
    }
    
    .tech-item {
        text-align: center;
        margin: 10px;
        cursor: pointer;
    }
    
    .tech-logo {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        object-fit: contain;
    }
    
    /* Responsive styles */
    @media (max-width: 1024px) {
        .tech-grid {
            justify-content: center; /* Center items on medium screens */
        }
    
        .tab--main--title {
            font-size: 1.8rem; /* Reduce title size */
        }
    
        .tech-logo {
            width: 70px;
            height: 70px; /* Slightly smaller logos */
        }
    }
    
    @media (max-width: 768px) {
        .all--tabs--container {
            align-items: center;
        }
    
        .tech-grid {
            justify-content: center; /* Center items on smaller screens */
        }
    
        .tab--main--title {
            font-size: 1.5rem; /* Reduce title size further */
        }
    
        .tech-logo {
            width: 60px;
            height: 60px; /* Smaller logos for smaller screens */
        }
    }
    
    @media (max-width: 480px) {
        .tech-item {
            margin: 5px;
        }
    
        .tab--main--title {
            font-size: 1.25rem; /* Smallest title size for mobile */
        }
    
        .tech-logo {
            width: 50px;
            height: 50px; /* Smallest logo size */
        }
    
        .tech-grid {
            gap: 10px; /* Reduce gap between items */
        }
    }
}

