.Header--wrapper {
  padding: 10px;
  position: relative;

  .tab--main--container {
    justify-content: center;
    align-items: center;
    width: 100%;

    .tech-logo {
      height: 40px;
      width: 40px;
    }

    .tab--main--wrapper {
      align-items: normal;
      flex-direction: row;

      .tech-grid {
        width: 70vw;
        gap: 50px;
      }

      .all--tabs--container {
        flex-direction: column;
      }
    }
  }

  .hamburger-menu {
    display: none;
  }
}

.header--wrapper--scrolled {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-in;
}

.services--tabs {
  display: flex;
  align-items: center;
  padding-left: 6.125rem;
  width: 100%;

  .get--in--touch {
    padding: 10px;
  }

  .tab--item {
    padding: 15px;
    height: 30px;
  }
}

.header--grid {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0px 90px 0px 90px;
}

/* Media Queries for Responsiveness */

/* Mobile Devices */
@media (max-width: 768px) {

  .accordion {
    padding: 20px;
  }

  .header--top {
    padding: 10px 0px 10px 0px !important;
    position: relative !important;
  }
  .hamburger-menu {
    display: block !important;
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    left: 20px;
    top: 20px;
  }
  .Header--wrapper {
    padding: 10px 20px;
  }

  .header--grid {
    flex-direction: column;
    margin: 0;
  }

  .services--tabs {
    flex-direction: column;
    padding-left: 0;
    padding-top: 20px;

    .accordion-item {
      .accordion-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .tab--item {
      padding: 10px 0;
    }

    .get--in--touch {
      margin-top: 10px;
      padding: 5px;
    }
  }

  /* Hide menu items for mobile, show a hamburger menu button */
  .services--tabs {
    display: none;
  }

  .open {
    .services--tabs {
      display: block;
    }
  }

  /* Fullscreen menu on click */
  .menu--open {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Tablet Devices */
@media (min-width: 768px) and (max-width: 1024px) {
  .hamburger-menu {
    display: block !important;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
  }
  .Header--wrapper {
    padding: 10px 30px;
  }

  .header--grid {
    flex-direction: column;
    margin: 0;
  }

  .services--tabs {
    flex-direction: column;
    padding-left: 0;

    .tab--item {
      padding: 10px 0;
      height: 34px;
    }
  }
}

/* Large Screens */
@media (min-width: 1025px) {
  .Header--wrapper {
    padding: 10px 0px 0px 0px;
  }

  .header--grid {
    flex-direction: row;
    margin: 0px 90px;
  }
}

.Header--wrapper {
  transition: transform 0.3s ease;
  position: fixed;
  background-color: #fff;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 .3125rem #0000006e;
}
.header--top {
  transition: transform 0.3s ease;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: none;
  border-bottom: 1px solid #ddd;

  .menu--open {
    background: radial-gradient(circle at 51.04% 55.62%, #fff2e2, transparent 37%), radial-gradient(circle at 77.85% 32.48%, #fff2e2, transparent 30%), radial-gradient(circle at 1.04% 6.9%, #fffcf7, transparent 13%), radial-gradient(circle at 33.61% 80.65%, #fff, transparent 27%), radial-gradient(circle at 73.06% 76.18%, #ffcdc1, transparent 69%), radial-gradient(circle at 66.25% 33.56%, #fbece5, transparent 38%), radial-gradient(circle at 18.82% 91.07%, #ffe3dd, transparent 25%), radial-gradient(circle at 67.43% 1.35%, #f3c8b3, transparent 14%), radial-gradient(circle at 38.26% 35.32%, #fff9f7, transparent 100%), radial-gradient(circle at 27.64% 59.27%, #fff9f7, transparent 77%), radial-gradient(circle at 55.07% 19.76%, #fee1cf, transparent 100%), radial-gradient(circle at 75.76% 58.19%, #ffcdc1, transparent 29%), radial-gradient(circle at 50% 50%, #ffeee0, #ffeee0 100%);
  }
}

.hide-header {
  transform: translateY(-100%); /* Slide the header out of view */
}

.header--wrapper--scrolled {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


