.testimonial-carousel {
  padding: 40px;
  background-color: #f8f9fa; /* Adjust to your theme */
  text-align: center;
  background: radial-gradient(circle at 60% 40%, #d6f7e7, transparent 35%),
            radial-gradient(circle at 20% 75%, #b3f2d1, transparent 40%),
            radial-gradient(circle at 80% 25%, #a4ebc8, transparent 30%),
            radial-gradient(circle at 40% 70%, #8de0ad, transparent 45%),
            radial-gradient(circle at 60% 55%, #76d8a4, transparent 40%),
            radial-gradient(circle at 50% 10%, #92e4b4, transparent 25%);

  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 30px;
    color: #6c757d;
  }

  /* Responsive for Tablet (max-width: 1024px) */
  @media (max-width: 1024px) {
    padding: 30px;

    h2 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }
  }

  /* Responsive for Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    padding: 20px;

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
}

.testimonial-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-content {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  /* Responsive for Tablet (max-width: 1024px) */
  @media (max-width: 1024px) {
    max-width: 500px;
    padding: 15px;
  }

  /* Responsive for Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 10px;
  }
}

.testimonial-content blockquote {
  font-size: 18px;
  font-style: italic;
  color: #333;

  /* Responsive for Tablet (max-width: 1024px) */
  @media (max-width: 1024px) {
    font-size: 16px;
  }

  /* Responsive for Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.testimonial-content cite {
  margin-top: 15px;
  display: block;
  font-size: 14px;
  color: #666;

  /* Responsive for Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.testimonial-content cite span {
  display: block;
}

/* Slick Arrow Styles */
.slick-arrow {
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
  z-index: 1;

  /* Responsive for Mobile (max-width: 600px) */
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.slick-prev {
  left: -35px; /* Adjust the positioning for mobile */
}

.slick-next {
  right: -35px; /* Adjust the positioning for mobile */
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .slick-prev {
    left: -25px;
  }

  .slick-next {
    right: -25px;
  }
}
