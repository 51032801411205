.image--with--text-section--grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px 95px; // Increased padding for better spacing
    gap: 20px;
    // background-color: #f9f9f9; // Light background for better contrast
    border-radius: 10px; // Rounded corners for a smoother look
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); // Subtle shadow for a card-like effect

    .text--section {
        width: 45%; // Reduced width for better spacing
        h3 {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 15px;
            color: #333; // Darker color for better contrast
        }

        p {
            font-size: 16px;
            line-height: 1.8;
            font-weight: 500;
            color: #000; // Slightly lighter text color for the description
        }
    }

    .image--container {
        width: 45%; // Reduced width for balance
        img {
            object-fit: cover; // Ensures the image covers the container
            width: 100%;
            height: 100%;
            border-radius: 8px; // Rounded corners for images
        }
    }

    /* Responsive for Tablet (max-width: 1024px) */
    @media (max-width: 1024px) {
        padding: 20px 40px;

        .text--section {
            h3 {
                font-size: 26px;
                line-height: 1.4;
            }

            p {
                font-size: 14px;
            }
        }

        .image--container {
            img {
                height: 250px;
                width: 250px;
            }
        }
    }

    /* Responsive for Mobile (max-width: 600px) */
    @media (max-width: 600px) {
        flex-direction: column;
        padding: 20px;
        gap: 20px;

        .text--section, .image--container {
            width: 100%;
        }

        .text--section {
            text-align: center;
            margin-bottom: 20px;

            h3 {
                font-size: 22px;
                line-height: 1.4;
            }

            p {
                font-size: 14px;
                margin-top: 10px;
            }
        }

        .image--container {
            img {
                width: 100%;
                max-width: 300px;
                height: auto;
            }
        }
    }
}

.reverse {
    .text--section {
        order: 2; // Moves text to the bottom on reverse
    }

    .image--container {
        order: 1; // Moves image to the top on reverse
    }

    /* On mobile, reverse should stack elements vertically */
    @media (max-width: 600px) {
        .text--section, .image--container {
            order: unset;
        }
    }
}
