.about-us {
  background-color: #f7f7f7; // Light background color for contrast
  padding: 60px 20px; // Padding to create space around the content
  text-align: center; // Center the text for visual appeal
  color: #333; // Primary text color

  &__content {
    max-width: 800px; // Limit the width of the content for readability
    margin: 0 auto; // Center the content block
    padding: 20px; // Internal padding for spacing
    background-color: #fff; // White background for text content
    border-radius: 10px; // Rounded corners for modern design
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  }

  &__heading {
    font-size: 2.5rem; // Larger font size for the heading
    margin-bottom: 20px; // Space below the heading
    color: #01cbff; // Brand color for emphasis
  }

  &__intro {
     text-align: start;
    font-size: 1.5rem; // Font size for the introductory text
    margin-bottom: 15px; // Spacing below the intro
  }

  &__text {
    text-align: start;
    font-size: 1.2rem; // Standard font size for the body text
    line-height: 1.6; // Improved readability with increased line height
    margin-bottom: 15px; // Space below each paragraph
  }

  .highlight {
    color: #01cbff; // Highlight color for names and key phrases
    font-weight: bold; // Emphasize important words
  }

  &__quote {
    font-style: italic; // Italic style for quotes
    font-size: 1.3rem; // Slightly larger font size for emphasis
    color: #555; // Subtle color for quotes
    margin: 30px 0; // Space around the quote
    padding: 20px; // Padding inside the quote
    background-color: #e9ecef; // Light background for quotes
    border-left: 5px solid #01cbff; // Visual indicator for the quote
  }

  &__call-to-action {
    font-size: 1.4rem; // Larger font for the call-to-action
    font-weight: bold; // Emphasize the call-to-action message
    margin-top: 25px; // Space above the call-to-action
    color: #333; // Darker color for visibility
  }

  &__button {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  // Responsive Design
  @media (max-width: 768px) {
    &__heading {
      font-size: 2rem; // Smaller font size for tablets
    }
    &__intro,
    &__text,
    &__call-to-action {
      font-size: 1.1rem; // Adjusted font size for readability on smaller screens
    }
  }

  @media (max-width: 480px) {
    padding: 40px 10px; // Less padding on mobile devices
    &__heading {
      font-size: 1.8rem; // Further reduce heading size for mobile
    }
    &__intro,
    &__text,
    &__call-to-action {
      font-size: 1rem; // Mobile-friendly text size
    }
  }

  .button--container {
    width: 180px;
    margin-top: 10px;
  }
}
