.stats-section {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.stat-card {
  background-color: #f7faff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-card h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.stat-card p {
  font-size: 1rem;
  color: #666;
}
