@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #f7f8fa !important; 
}

html {
  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
}

/* Styling for the expanding ring */
.cursor-ring {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #ffc30c; /* Single color for the ring */
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  animation: expandRing 0.8s ease-out forwards;
  opacity: 1;
  z-index: 99999;
}

/* Ring expansion animation */
@keyframes expandRing {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}



.company--clients--wrapper {
  background: radial-gradient(circle at 60% 40%, #f1fce8, transparent 30%), 
            radial-gradient(circle at 20% 75%, #e8ffe4, transparent 35%), 
            radial-gradient(circle at 80% 25%, #fffbe0, transparent 20%), 
            radial-gradient(circle at 40% 70%, #f5ffe6, transparent 50%), 
            radial-gradient(circle at 60% 55%, #ebffdb, transparent 40%), 
            radial-gradient(circle at 50% 10%, #e6ffcc, transparent 25%);

}

.Client--and--technologies--wrapper {
  background: radial-gradient(circle at 60% 40%, #f9b7c1, transparent 35%), 
            radial-gradient(circle at 20% 75%, #f5a1b3, transparent 40%), 
            radial-gradient(circle at 80% 25%, #f7c7c0, transparent 30%), 
            radial-gradient(circle at 40% 70%, #fcb8c3, transparent 50%), 
            radial-gradient(circle at 60% 55%, #f3a6b8, transparent 40%), 
            radial-gradient(circle at 50% 10%, #f6c0c5, transparent 25%);


}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #fff;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
