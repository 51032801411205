.client-showcase {
  padding: 50px;
  text-align: center;

  .client-info {
    margin-bottom: 30px;

    h2 {
      font-size: 2rem;
      color: #333;
      margin-bottom: 15px;
      animation: fadeInUp 0.5s forwards; // Add animation for h2
      opacity: 0; // Start with hidden opacity
      transform: translateY(20px); // Start from below
      animation-delay: 0.3s; // Delay for the fade-in effect
    }

    p {
      font-size: 1rem;
      color: #555;
      line-height: 1.5;
      animation: fadeInUp 0.5s forwards; // Add animation for paragraph
      opacity: 0; // Start with hidden opacity
      transform: translateY(20px); // Start from below
      animation-delay: 0.5s; // Delay for the fade-in effect
    }
  }

  .client-logos {
    .slick-slide {
      display: flex;
      justify-content: center;

      .logo-container {
        max-width: 150px;
        transition: transform 0.3s ease;

        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          transition: transform 0.3s ease; // Smooth image scaling
        }

        &:hover {
          transform: scale(1.1); // Slightly scale up on hover
          
          img {
            transform: scale(1.1); // Scale image on hover
          }
        }
      }
    }

    .slick-dots {
      bottom: -30px; // Adjust dot position
      li {
        button {
          &:before {
            font-size: 10px;
            color: #555; // Color for dots
          }
        }

        &.slick-active {
          button {
            &:before {
              color: #40bfbf; // Active dot color
            }
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1;
      color: #40bfbf; // Arrow color
      transition: color 0.3s ease;

      &:hover {
        color: #2da8a8; // Arrow hover color
      }
    }
  }
}

// Fade-in effect animation
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) { 
  .client-showcase {
    padding: 0px 0px 20px 0px;
  }
}
